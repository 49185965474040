<template>
  <div class="base-modal-info__item base-modal-info__item--comments">
    <div class="base-modal-info__name">
      <icon-comment/>
      <span>Комментарии</span>
    </div>
    <div class="base-modal-info__value">
      <div class="base-modal-create-application__comments">
        <div class="textarea-wrapper textarea-wrapper--comment">
          <div class="form-item__value">
            <textarea
              name="comments"
              maxlength="1500"
              placeholder="Напишите сообщение"
              class="base-textarea base-textarea--borderless"
              v-model="payload.comment"/>
          </div>
          <base-loader
            v-if="isLoading"
            width="24px"
            height="24px"
          />
          <div v-else
               @click="saveComment()"
               class="textarea-wrapper--send">
            <icon-send/>
          </div>
        </div>

        <div
          v-if="commentsList.length"
          class="task-comments__list"
        >
          <div
            v-for="comment in commentsList"
            :key="comment.id"
            class="task-comments__item">
            <div class="task-comments__meta">
              {{comment.author}}
              <span>{{$moment(comment.created_date).fromNow()}}</span>
            </div>
            <div class="task-comments__message">{{comment.comment}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconComment from "@/components/Icons/IconComment";
import IconSend from "@/components/Icons/IconSend";
import {showAlert, showToast} from "@/utils/notification";
import BaseLoader from "@/layout/BaseLoader.vue";

export default {
  name: "CommentsList",
  components: {BaseLoader, IconSend, IconComment},
  props: {
    taskId: {
      type: Number,
      required: true
    },
    commentsList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      payload: {
        comment: null
      },
      isLoading: false
    }
  },
  methods: {
    saveComment() {
      this.isLoading = true;
      this.$store.dispatch('task/addComment', {
        task_id: this.taskId,
        payload: this.payload
      })
        .then(() => {
          this.payload.comment = null;
          this.$store.dispatch('taskModal/getCommentsList', this.taskId);
          showToast('Комментарий добавлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
        .finally(() => this.isLoading = false);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.textarea-wrapper {
  &--comment {
    display: flex;

    & textarea {
      min-height: 24px;
      width: 100%;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &--send {
    cursor: pointer;
  }

}

.task-comments {
  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    margin-top: 24px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__meta {
    margin-right: 8px;

    @include font-style(600, 16px, 24px, var(--text-primary));

    & span {
      padding-left: 8px;

      @include font-style(400, 16px, 24px, var(--text-secondary));
    }
  }

  &__message {
    @include font-style(400, 14px, 16px, var(--text-primary));
  }
}
</style>
