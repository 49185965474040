import TaskState from "@/models/serviceDesk/TaskState";
import moment from "moment";
import {getDatesRangeCaption} from "@/utils/helpers";

export default class Task {
    constructor(id, attributes) {
        this.id = id;
        this.issue_id = attributes.issue?.id || attributes.issue_id || null;
        this.type_id = attributes.type.id;
        this.type_label = attributes.type.name;
        this.typeFeatures = attributes.type.components || [];
        this.order_id = attributes.order_id || null;
        this.state = new TaskState(attributes.state);
        this.planned_start_date = attributes.planned_start_date || null;
        this.planned_end_date = attributes.planned_end_date || null;
        this.actual_start_date = attributes.actual_start_date || null;
        this.actual_end_date = attributes.actual_end_date || null;
        this.brigade_name = attributes.brigade?.name || null;
        this.department = attributes.department || null;
        this.permit = attributes.permit;
        this.checkSafety = attributes.type.check_safety || null;
    }

    get plannedDatesRangeCaption() {
        const start = moment(this.planned_start_date);
        const end = moment(this.planned_end_date);

        return getDatesRangeCaption(start, end);
    }
}
