<template>
  <base-popup
    :title="popupTitle"
    :name="popupName"
  >
    <template #body>
      <div class="form-item">
        <div class="form-item__name">Наряд-допуск</div>
        <div class="form-item__value">
          <base-drag-and-drop-file-input
            accepted-files=".pdf"
            @change="handleFileUpload"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <button
        @click="submitForm()"
        class="button button--mode-primary"
      >
        {{buttonTitle}}
      </button>
    </template>
  </base-popup>
</template>

<script>
import BasePopup from "@/components/BasePopup.vue";
import {showToast, showAlert} from "@/utils/notification";
import BaseDragAndDropFileInput from "@/components/BaseDragAndDropFileInput.vue";

export default {
  name: "UploadPermitModal",
  components: {BaseDragAndDropFileInput, BasePopup},
  props: {
    popupName: {
      type: String,
      required: true
    },
    isStartForm: {
      type: Boolean,
      required: true
    },
    taskId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      error: false,
      file: null
    }
  },
  computed: {
    popupTitle() {
      return this.isStartForm ? 'Выдать наряд-допуск' : 'Закрыть наряд допуск';
    },
    buttonTitle() {
      return this.isStartForm ? 'Выдать' : 'Завершить'
    }
  },
  methods: {
    handleFileUpload(file) {
      const formData = new FormData();
      formData.append('permit_file', file);

      this.file = formData;
    },
    startPermit() {
      if (!this.file) {
        this.error = true;
        return false
      }

      this.$store.dispatch('task/startTaskPermit', {
        taskId: this.taskId,
        file: this.file
      })
        .then(() => {
          this.$modal.hide(this.popupName);
          this.$store.dispatch('taskModal/getTaskInfo', this.taskId)
          showToast('Наряд-допуск загружен', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    finishPermit() {
      if (!this.file) {
        this.error = true;
        return false
      }

      this.$store.dispatch('task/finishTaskPermit', {
        taskId: this.taskId,
        file: this.file
      })
        .then(() => {
          this.$modal.hide(this.popupName);
          this.$store.dispatch('taskModal/getTaskInfo', this.taskId)
          showToast('Наряд-допуск загружен', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    submitForm() {
      if (this.isStartForm) this.startPermit();
      else this.finishPermit();
    }
  }
}
</script>

<style scoped>

</style>
