<script>
import IconTrash from "@/components/Icons/IconTrash.vue";

export default {
  name: "ResourceMembersFormItem",
  components: {IconTrash},
  props: {
    fullName: {},
    fullNameError: {},
    position: {},
    positionError: {},
    category: {},
    electricityGroup: {},
    foreman: {},
    arrIndex: {}
  }
}
</script>

<template>
  <div class="resource-members__item">
    <div class="resource-members__form">
      <div class="resource-members__name">
        <input
          :value="fullName"
          class="base-input base-input--bordered"
          :class="{'base-input--error': fullNameError}"
          type="text"
          placeholder="ФИО"
          @input="$emit('full-name', $event.target.value)"
        >
      </div>
      <div class="resource-members__position">
        <input
          :value="position"
          class="base-input base-input--bordered"
          :class="{'base-input--error': positionError}"
          type="text"
          placeholder="Должность"
          @input="$emit('position', $event.target.value)"
        >
      </div>
      <div class="resource-members__category">
        <input
          :value="category"
          class="base-input base-input--bordered"
          type="text"
          placeholder="Группа"
          @input="$emit('category', $event.target.value)"
        >
      </div>
      <div class="resource-members__electricity-group">
        <input
          :value="electricityGroup"
          class="base-input base-input--bordered"
          type="text"
          placeholder="Разряд"
          @input="$emit('electricity-group', $event.target.value)"
        >
      </div>
      <div class="resource-members__foreman">
        <label class="base-switcher">
          <input
            :checked="foreman"
            class="base-switcher__checkbox"
            type="checkbox"
            @input="$emit('foreman', $event.target.checked)"
          >
          <div class="base-switcher__switch"/>
          <span class="base-switcher__label">
            Производитель работ
          </span>
        </label>
      </div>
    </div>

    <div
      @click="$emit('remove-member', arrIndex)"
      class="resource-members__action">
      <icon-trash/>
    </div>
  </div>
</template>

<style lang="scss">
.resource-members {
  &__item {
    display: flex;
    align-items: center;
    gap: 24px;

    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--border-main);

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    align-items: center;
    row-gap: 12px;
    column-gap: 24px;
  }

  &__name {
    grid-column: 1 / 3;
  }

  &__foreman label {
    margin-bottom: 0;
  }

  &__action {
    svg {
      stroke: var(--ico);
    }
  }
}
</style>
