import * as API from "@/services/API";
import Task from "@/models/serviceDesk/Task";
import {getError} from "@/utils/helpers";
import Issue from "@/models/serviceDesk/Issue";
import File from "@/models/serviceDesk/File";
import DepartmentBrigade from "@/models/serviceDesk/DepartmentBrigade";
import Department from "@/models/serviceDesk/Department";
import TaskMechanicalEquipment from "@/models/serviceDesk/TaskMechanicalEquipment";
import {saveAs} from 'file-saver'

export default {
    getUserTasks(page) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/tasks?page=${page}`)
                .then(response => {
                    resolve({
                        tasks: response.data.data.map(taskItem => {
                            const {brigade, issue, department} = taskItem.attributes;
                            return getTaskObject(taskItem, brigade, issue, department);
                        }),
                        meta: response.data.meta,
                        links: response.data.links
                    })
                })
                .catch(error => reject(getError(error)));
        })
    },
    getTask(task_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/tasks/${task_id}`)
                .then(response => {
                    const task = response.data.data;
                    const {brigade, issue, department} = response.data.data.attributes;
                    resolve(getTaskObject(task, brigade, issue, department));
                })
                .catch(error => reject(getError(error)));
        })
    },
    assignBrigade({task_id, payload}) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`service-desk/tasks/${task_id}/change-planning`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    getTasks(filterParams) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/tasks`, {params: filterParams})
                .then(response => {
                    resolve(response.data.data.map(task_item => {
                        const {brigade, issue, department} = task_item.attributes;
                        return getTaskObject(task_item, brigade, issue, department)
                    }))
                })
                .catch(error => reject(getError(error)));
        })
    },
    fetchAllTasks(filterParams) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/tasks/all`, {params: filterParams})
                .then(response => {
                    resolve(response.data.data.map(task_item => {
                        const {brigade, issue, department} = task_item.attributes;
                        return getTaskObject(task_item, brigade, issue, department);
                    }))
                })
                .catch(error => reject(getError(error)));
        })
    },
    addFile(payload) {
        return API.apiClient.post(`service-desk/tasks/${payload.task_id}/attachments`, payload.formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    deleteFile(taskId, fileId) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/service-desk/tasks/${taskId}/attachments/${fileId}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    getTaskFiles(task_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/tasks/${task_id}/attachments`)
                .then(response => {
                    resolve(response.data.data.map(file => new File(file.id, file.attributes)));
                })
                .catch(error => reject(getError(error)));
        })
    },
    acceptTask(task_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/tasks/${task_id}/accept`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    cancelTask(task_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/tasks/${task_id}/cancel`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    onTheWayTask(task_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/service-desk/tasks/${task_id}/lets-go`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    startTask(task_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/tasks/${task_id}/start`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    suspendTask(task_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/tasks/${task_id}/suspend`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    resumeTask(task_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/tasks/${task_id}/resume`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    completeTask(task_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/tasks/${task_id}/complete`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    reviewTask(task_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/tasks/${task_id}/review`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    addWork({task_id, payload}) {
        return API.apiClient.post(`service-desk/tasks/${task_id}/works`, payload);
    },
    removeWork(taskId, workId) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/service-desk/tasks/${taskId}/works/${workId}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    addComment({task_id, payload}) {
        return API.apiClient.post(`service-desk/tasks/${task_id}/comments`, payload);
    },
    appointDepartment(task_id, payload) {
        return API.apiClient.post(`/service-desk/tasks/${task_id}/appoint-department`, payload)
    },
    fetchTaskMechanicalEquipments(taskId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/tasks/${taskId}/equipments`)
                .then(response => resolve(response.data.data
                    .map(item => new TaskMechanicalEquipment(item))))
                .catch(error => reject(getError(error)));
        })
    },
    addMechanicalEquipment(taskId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/service-desk/tasks/${taskId}/equipments`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    removeMechanicalEquipment(taskId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/service-desk/tasks/${taskId}/equipments`, {
                data: payload
            })
                .then(id => resolve(id))
                .catch(error => reject(getError(error)));
        })
    },
    changePlanning(taskId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/service-desk/tasks/${taskId}/change-planning`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    fetchTaskPermit(taskId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/tasks/${taskId}/make-permit`, {
                responseType: 'arraybuffer'
            })
                .then(response => {
                    const contentType = response.headers['content-type'];

                    const blob = new Blob([response.data], { type: contentType });
                    const filename = response.headers['content-disposition']
                        ? response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
                        : 'Наряд-допуск.docx';

                    saveAs(blob, filename);

                    resolve();
                })
                .catch(error => reject(getError(error)))
        })
    },
    startTaskPermit(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/service-desk/tasks/${payload.taskId}/start-work-permit`, payload.file, {
                headers: {'Content-Type': 'multipart/form-data'}
            })
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    finishTaskPermit(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/service-desk/tasks/${payload.taskId}/finish-work-permit`, payload.file, {
                headers: {'Content-Type': 'multipart/form-data'}
            })
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}

function getTaskObject(task, brigade, issue, department) {
    let taskItem = new Task(task.id, {
        type: task.attributes.type,
        state: task.attributes.state,
        planned_start_date: task.attributes.planned_start_date,
        planned_end_date: task.attributes.planned_end_date,
        actual_end_date: task.attributes.actual_end_date,
        actual_start_date: task.attributes.actual_start_date,
        permit: task.attributes.permit
    })
    taskItem.issue = new Issue(issue.id, {
        address: issue.address,
        created_at: issue.created_at,
        state: issue.state
    })

    if (brigade !== null) taskItem.brigade = new DepartmentBrigade({
        id: brigade.id,
        attributes: {
            name: brigade.name
        }
    });

    if (department) {
        taskItem.department = new Department(department.id, {
            name: department.name
        })
    } else taskItem.department = null

    return taskItem
}
