<template>
  <div class="page-wrapper">
    <base-panel
      title="Объекты"
      :is-add-button-visible="false"
    />

    <base-functional-line @search-change="event => query = event.target.value"/>

    <technical-inspection-objects-list
      v-if="objectsList.length"
      :objects-list="objectsList"
      @show-object="openModal"
    />

    <base-content-loaders v-if="loading && !objectsList.length"/>

    <base-no-data v-if="!loading && !objectsList.length"/>

    <base-paginate
      v-if="meta && meta.last_page > 1"
      :meta="meta"
      commit="technicalInspectionObjects/SET_PAGE"
      action="technicalInspectionObjects/getTechnicalInspectionObjectsList"
    />
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
import TechnicalInspectionObjectsList
  from "@/components/TechnicalInspection/NetworkObjects/TechnicalInspectionObjectsList.vue";
import {mapState} from "vuex";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import BasePaginate from "@/components/BasePaginate.vue";

export default {
  name: "TechnicalInspectionObjectsListPage",
  components: {
    BasePaginate,
    BaseNoData, BaseContentLoaders, TechnicalInspectionObjectsList, BaseFunctionalLine, BasePanel
  },
  computed: {
    ...mapState({
      objectsList: state => state.technicalInspectionObjects.objectsList,
      loading: state => state.technicalInspectionObjects.loading,
      meta: state => state.technicalInspectionObjects.meta
    }),
    query: {
      get() {
        return this.$store.state.technicalInspectionObjects.query
      },
      set(value) {
        this.$store.commit('technicalInspectionObjects/SET_QUERY', value);
      }
    }
  },
  watch: {
    query: function () {
      this.$store.dispatch('technicalInspectionObjects/getTechnicalInspectionObjectsList');
    }
  },
  mounted() {
    this.$store.dispatch('technicalInspectionObjects/getTechnicalInspectionObjectsList');
  },
  methods: {
    openModal(objectId) {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'technicalInspectionObject',
        entityId: objectId
      });
    }
  }
}
</script>

<style scoped>

</style>
