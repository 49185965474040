export default class Damage {
    constructor(params) {
        this.id = params.id;
        this.issueId = params.issue_id;
        this.defect = params.defect;
        this.needShutdown = params.need_shutdown;
        this.startDate = params.start_date;
        this.endDate = params.end_date;
        this.description = params.description;
        this.emergencyElement = params.emergency_element;
        this.defectParams = params.defect_params;
    }

    get isDefectWithWormholes() {
        return this.defect?.type.id === 1;
    }

    get isDefectWithFlaw() {
        return this.defect?.type.id === 2;
    }

    get isDefectWithHole() {
        return this.defect?.type.id === 3;
    }

    get isDefectWithBreak() {
        return this.defect?.type.id === 5;
    }

    get isDamageWithDefect() {
        return !!this.defect
    }
}