<template>
  <div
    class="base-suggestions"
    :class="{'base-suggestions--error': validationError}"
  >
    <input
      ref="inputElement"
      v-model="model"
      type="text"
      autocomplete="off"
      :placeholder="placeholder"
      @input="onInput"
      @focus="isFocused = true"
      @blur="onBlur"
      @keydown.down.prevent="onArrowDown"
      @keydown.up.prevent="onArrowUp"
      @keydown.enter.prevent="onEnter"
    >

    <div
      class="base-suggestions__list"
      :class="{'visible': isFocused}"
    >
      <div
        v-for="(suggestion, index) in suggestionsList"
        :key="suggestion.value"
        @click="selectSuggestion(suggestion)"
        class="base-suggestions__item"
        :class="{'active': index === highlightedIndex}"
      >
        {{suggestion.value}}
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "BaseSuggestions",
  props: {
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'address'
    },
    suggestionsCount: {
      type: Number,
      default: 5
    },
    placeholder: {
      type: String,
      default: 'Начните ввода'
    },
    validationError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      model: '',
      timeout: null,
      isFocused: false,
      highlightedIndex: -1,
      oldQuery: '',
      isSelecting: false
    }
  },
  computed: {
    ...mapState({
      suggestionsList: state => state.suggestions.suggestionsList
    })
  },
  watch: {
    value() {
      this.model = this.value;
    }
  },
  beforeDestroy() {
    this.$store.commit('suggestions/SET_SUGGESTIONS_LIST', []);
  },
  methods: {
    onInput(event) {
      this.isSelecting = false;
      this.$emit('input', this.model);

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.getSuggestionsList(event.target.value);
      }, 300);
    },
    onBlur() {
      setTimeout(() => {
        this.isFocused = false;
      }, 200);
    },
    onArrowDown() {
      if (!this.isSelecting) {
        this.oldQuery = this.model;
        this.isSelecting = true;
      }

      if (this.highlightedIndex === this.suggestionsList.length - 1) {
        this.highlightedIndex = -1;
        this.$refs.inputElement.focus();
        this.model = this.oldQuery;

        return;
      }

      if (this.highlightedIndex < this.suggestionsList.length - 1) {
        this.highlightedIndex++;

        const selectedValue = this.suggestionsList[this.highlightedIndex];
        this.model = selectedValue.value;
      }
    },
    onArrowUp() {
      if (!this.isSelecting) {
        this.oldQuery = this.model;
        this.isSelecting = true;
      }

      if (this.highlightedIndex === 0) {
        this.highlightedIndex = -1;
        this.$refs.inputElement.focus();
        this.model = this.oldQuery;

        return;
      }

      if (this.highlightedIndex > 0) {
        this.highlightedIndex--;

        const selectedValue = this.suggestionsList[this.highlightedIndex];
        this.model = selectedValue.value;
      }
    },
    onEnter() {
      if (this.highlightedIndex !== -1) {
        this.selectSuggestion(this.suggestionsList[this.highlightedIndex]);
      }
    },
    selectSuggestion(suggestion) {
      this.oldQuery = suggestion.value;
      this.$emit('input', suggestion.value);
      this.$emit('change', suggestion);
      this.$refs.inputElement.blur();
    },
    getSuggestionsList(query) {
      this.$store.dispatch('suggestions/getSuggestionsList', {
        query,
        count: this.suggestionsCount,
        type: this.type
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.base-suggestions {
  position: relative;

  & input {
    width: 100%;
    padding: 12px 24px;
    border: 1px solid var(--border-main);
    border-radius: 16px;
    background-color: var(--panel);
    @include font-style(400, 16px, 24px, var(--text-primary));

    &::placeholder {
      color: var(--text-placeholder);
    }

    &:focus {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:focus-within {
      outline: none;
    }
  }

  &--error {
    & input {
      border-color: var(--border-validation);
    }
  }

  &__list {
    position: absolute;

    visibility: hidden;
    opacity: 0;

    border: 1px solid var(--border-main);
    border-top: none;
    box-shadow: 0 8px 8px 0 rgba(145, 146, 173, .1);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    width: 100%;
    background-color: var(--panel);

    z-index: 9999;

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }

  &__item {
    padding: 4px 24px;
    @include font-style(400, 16px, 24px, var(--text-primary));

    &:hover {
      background-color: var(--panel-bg);
    }

    &.active {
      background-color: var(--panel-bg);
    }

    &:last-child {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}
</style>
