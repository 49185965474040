import * as API from './API';
import NetworkObject from "@/models/telemetry/NetworkObject";
import {getError} from "@/utils/helpers";
import NetworkObjectTelemetry from "@/models/telemetry/NetworkObjectTelemetry";

export default {
    getAllNetworkObjects(filterParams) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/network-objects`, {params: filterParams})
                .then(response => resolve(response.data.data.map(item => new NetworkObject(item))))
                .catch(error => reject(getError(error)));
        })
    },
    fetchNetworkObject(networkObjectId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/network-objects/${networkObjectId}`)
                .then(response => resolve(new NetworkObject(response.data.data)))
                .catch(error => reject(getError(error)))
        })
    },
    fetchTelemetryNetworkObjects() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/network-objects`)
                .then(response => resolve(response.data.data.map(item => new NetworkObjectTelemetry(
                    item,
                    !JSON.parse(localStorage.getItem('hiddenInList')).includes(item.id),
                    !JSON.parse(localStorage.getItem('hiddenOnMap')).includes(item.id),
                    JSON.parse(localStorage.getItem('fixedOnMap')).includes(item.id)
                ))))
                .catch(error => reject(getError(error)));
        })
    },
    fetchNetworkObjectsByBounds(bounds) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/technical-inspection/objects/search`, {params: bounds})
                .then(response => resolve(response.data.data.map(item => new NetworkObject(item))))
                .catch(error => reject(getError(error)));
        })
    },
    createNetworkObject(payload) {
        return API.apiClient.post(`/network-objects`, payload);
    },
    updateNetworkObject(networkObjectId, payload) {
        return API.apiClient.patch(`/network-objects/${networkObjectId}`, payload);
    },
    deleteNetworkObject(networkObjectId) {
        return API.apiClient.delete(`/network-objects/${networkObjectId}`);
    }
}
