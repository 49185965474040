var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-table base-table--tasks"},[_c('div',{staticClass:"base-table__container"},[_vm._m(0),_vm._l((_vm.tasksList),function(task){return _c('div',{key:task.id,staticClass:"base-table__row base-table__row--task",on:{"click":function($event){return _vm.openTaskModal(task.id)}}},[_c('div',{staticClass:"base-table__column base-table__column--number"},[_c('div',{staticClass:"base-table__num"},[_vm._v(_vm._s(task.id))])]),_c('div',{staticClass:"base-table__column"},[_c('div',{staticClass:"base-table__text"},[_vm._v(_vm._s(task.type_label))])]),_c('div',{staticClass:"base-table__column"},[_c('div',{staticClass:"base-table__text"},[_vm._v(_vm._s(task.plannedDatesRangeCaption))])]),_c('div',{staticClass:"base-table__column"},[_c('div',{staticClass:"base-table__text"},[_vm._v(_vm._s(task.issue.getFullAddress()))])]),_c('div',{staticClass:"base-table__column base-table__column--issue"},[_c('div',{staticClass:"base-table__join-link",on:{"click":function($event){$event.stopPropagation();return _vm.openIssueModal(task.issue.id)}}},[_c('icon-link'),_c('div',{staticClass:"base-table__join-counter"},[_vm._v("#"+_vm._s(task.issue.id))])],1)]),_c('div',{staticClass:"base-table__column"},[_c('div',{staticClass:"base-table__tag base-table__tag--secondary"},[_vm._v(_vm._s(task.brigade ? task.brigade.name : '—'))])]),_c('div',{staticClass:"base-table__column base-table__column--state"},[_c('div',{staticClass:"base-table__tag",class:[
               {'base-table__tag--new': task.state.id === _vm.TaskState.NEW},
               {'base-table__tag--work': task.state.id === _vm.TaskState.IN_WORK},
               {'base-table__tag--done': task.state.id === _vm.TaskState.CLOSED},
               {'base-table__tag--canceled': task.state.id === _vm.TaskState.CANCELED},
               {'base-table__tag--suspended': task.state.id === _vm.TaskState.SUSPENDED},
               {'base-table__tag--planned': task.state.id === _vm.TaskState.ON_THE_WAY},
               {'base-table__tag--review': task.state.id === _vm.TaskState.REVIEW}
             ]},[_vm._v(" "+_vm._s(task.state.label)+" ")])])])})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-table__header base-table__header--task"},[_c('div',{staticClass:"base-table__header-cell"},[_vm._v("ID")]),_c('div',{staticClass:"base-table__header-cell"},[_vm._v("Работы")]),_c('div',{staticClass:"base-table__header-cell"},[_vm._v("План. нач/оконч")]),_c('div',{staticClass:"base-table__header-cell"},[_vm._v("Адрес")]),_c('div',{staticClass:"base-table__header-cell"},[_vm._v("Заявка")]),_c('div',{staticClass:"base-table__header-cell"},[_vm._v("Бригада")]),_c('div',{staticClass:"base-table__header-cell"},[_vm._v("Статус")]),_c('div',{staticClass:"base-table__header-cell"})])}]

export { render, staticRenderFns }