import CategoryGroup from "@/models/serviceDesk/CategoryGroup";

export default class TaskType {
    constructor(id, attributes) {
        this.id = id;
        this.name = attributes.name;
        this.tag = attributes.tag;
        this.features = attributes.components;
        this.categoryGroups = attributes.category_groups ? attributes.category_groups
            .map(item => new CategoryGroup({
                    id: item.id,
                    ...item.attributes
                })) : []
        this.leadTime = attributes.lead_time;
        this.stage = attributes.stage;
        this.active = !!attributes.active;
        this.safetyMeasures = attributes.safety_measures;
        this.checkSafety = !!attributes.check_safety;
    }
}
