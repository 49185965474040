<template>
  <div>
    <div class="base-modal-info__item">
      <div class="base-modal-info__name">
        <span>Категория объекта</span>
      </div>
      <div class="base-modal-info__value">
        <p class="base-modal-info__value-txt">{{ typeLabel }}</p>
      </div>
    </div>

    <div class="base-modal-info__item">
      <div class="base-modal-info__name">
        <span>Тип объекта</span>
      </div>
      <div class="base-modal-info__value">
        <p class="base-modal-info__value-txt">{{ categoryName }}</p>
      </div>
    </div>

    <div class="base-modal-info__item">
      <div class="base-modal-info__name">
        <span>Телефон</span>
      </div>
      <div class="base-modal-info__value">
        <p class="base-modal-info__value-txt">{{ phone }}</p>
      </div>
    </div>

    <div class="base-modal-info__item">
      <div class="base-modal-info__name">
        <span>Балансовая принадлежность</span>
      </div>
      <div class="base-modal-info__value">
        <p class="base-modal-info__value-txt">{{ nullFilter(balanceSheetName) }}</p>
      </div>
    </div>

    <div class="base-modal-info__item">
      <div class="base-modal-info__name">
        <span>Структурные подразделения</span>
      </div>
      <div class="base-modal-info__value">
        <p class="base-modal-info__value-txt">{{ structuralUnitsNames }}</p>
      </div>
    </div>

    <div class="base-modal-info__item">
      <div class="base-modal-info__name">
        <span>Адрес</span>
      </div>
      <div class="base-modal-info__value">
        <p class="base-modal-info__value-txt">{{ address }}</p>
      </div>
    </div>

    <div class="i-modal-location"
         :class="{'is-open': isMapVisible}">
      <div class="i-modal-location__map">

        <base-map
          id-key="-network-object-modal"
          :center="coordinates"
          :zoom="17"
          :base-layers="baseLayers"
          :markers="createMarker"
          :need-resize="isMapVisible"
        />

      </div>
      <button class="button button--mode-icon map-resize-btn"
              @click="isMapVisible = !isMapVisible">
        <icon-arrow-double-up/>
      </button>
    </div>
  </div>
</template>

<script>
import BaseMap from "@/components/BaseMap.vue";
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp.vue";
import {nullFilter} from "@/utils/filters";
import {mapState} from "vuex";
import Vue from "vue";
import PersonalListNetworkObjectMapMarker
  from "@/components/Telemetry/PersonalLists/PersonalListNetworkObjectMapMarker.vue";
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon";

export default {
  name: "NetworkObjectDetails",
  components: {IconArrowDoubleUp, BaseMap},
  props: {
    typeLabel: {
      type: String
    },
    categoryName: {
      type: String
    },
    categoryGroupName: {
      type: String
    },
    phone: {
      type: String
    },
    balanceSheetName: {
      type: String
    },
    structuralUnitsNames: {
      type: String
    },
    address: {
      type: String
    },
    coordinates: {
      type: Array
    }
  },
  data() {
    return {
      isMapVisible: false
    }
  },
  computed: {
    ...mapState({
      baseLayers: state => state.baseLayers.baseLayers
    }),
    createMarker() {
      const ObjectIconClass = Vue.extend(PersonalListNetworkObjectMapMarker);
      const iconInstance = new ObjectIconClass();
      iconInstance.typeGroup = this.categoryGroupName;
      const icon = iconInstance.$mount();
      const iconHtml = icon.$el.outerHTML;

      const marker = [
        new Marker({
          coords: this.coordinates,
          icon: new Icon({
            type: 'div_icon',
            size: [36, 42],
            anchor: [18, 21],
            html: iconHtml
          })
        })
      ]

      icon.$destroy();

      return marker;
    }
  },
  methods: {
    nullFilter
  }
}
</script>

<style scoped>

</style>
