import TechnicalInspectionTask from "@/models/TechnicalInspection/TechnicalInspectionTask";
import QuestionAnswer from "@/models/TechnicalInspection/QuestionAnswer";
import TaskViolation from "@/models/TechnicalInspection/TaskViolation";

export class TechnicalInspectionObjectLastTask extends TechnicalInspectionTask {
    constructor(params) {
        super(params);

        this.answers = params.answers.map(item => new QuestionAnswer({id: item.id, ...item.attributes}));
        this.violations = params.violations.map(item => new TaskViolation({id: item.id, ...item.attributes}));
    }
}
