export default class WarehouseStock {
    constructor(params) {
        this.consumableId = params.consumable.id;
        this.consumableName = params.consumable.name;
        this.consumableMeasure = params.consumable.measure;
        this.amount = params.available;
        this.reserv = params.reserv;
    }

    get available() {
        return this.amount - this.reserv;
    }
}
