import WarehousesService from "@/services/WarehousesService";

export const namespaced = true;

export const state = {
    warehousesList: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_WAREHOUSES_LIST(state, warehousesList) {
        state.warehousesList = warehousesList;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getWarehousesList({commit}) {
        commit("SET_LOADING", true);
        WarehousesService.fetchWarehousesList()
            .then(warehouses => commit("SET_WAREHOUSES_LIST", warehouses))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    }
}
