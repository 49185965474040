import NetworkObjectType from "@/models/telemetry/NetworkObjectType";

export class TechnicalInspectionListObject {
    constructor(params) {
        this.id = params.id;
        this.name = params.name;
        this.constructionType = params.construction_type;
        this.type = new NetworkObjectType(params.type);
        this.address = params.address;
        this.coordinates = params.coordinates.coordinates.reverse();
        this.activeViolations = params.active_violations || null;
        this.lastInspectionDate = params.last_inspection?.date || null;
        this.lastInspectionResource = params.last_inspection?.resource_name || null;
    }
}
