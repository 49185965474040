<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div v-if="Object.keys(resource).length && !loading"
           class="i-modal__inner">
        <div class="base-modal__left-side base-modal__left-side--hidden">
          <div class="base-modal-header base-modal-header--padding-s">
            <span class="base-modal-title">{{ resource.name }}</span>
          </div>

          <div class="base-modal-info base-modal-info--gap-24 base-modal-info--padding-s">
            <div class="base-modal-info__card">
              <div class="base-modal-info__card-title base-modal-info__card-title--loader">
                Детальная информация
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Наименование</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ resource.name }}</p>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Мастер</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ resource.master.name }}</p>
                </div>
              </div>

              <div class="base-modal-info__item">
                <div class="base-modal-info__name">
                  <span>Подразделение</span>
                </div>
                <div class="base-modal-info__value">
                  <p class="base-modal-info__value-txt">{{ resource.structuralUnit.name }}</p>
                </div>
              </div>
            </div>

            <div>
              <div class="tabs">
                <div class="tabs__header tabs__header--block">
                  <div
                    @click="activeTab = 0"
                    :class="{'active': activeTab === 0}"
                    class="tabs__header-item">
                    Текущие задания
                  </div>

                  <div
                    @click="activeTab = 1"
                    :class="{'active': activeTab === 1}"
                    class="tabs__header-item">
                    Склад
                  </div>
                </div>

                <div :class="{'active': activeTab === 0}"
                     class="tabs__item">
                  <div
                    v-for="(tasks, date) in groupedTasks"
                    :key="date"
                    class="resource-tasks"
                  >
                    <div class="resource-tasks__date">
                      {{ formatTaskDate(date) }}
                    </div>

                    <div class="resource-tasks__list">
                      <div
                        v-for="task in tasks"
                        :key="task.id"
                        class="added-item added-item--flex"
                      >
                        <div class="added-item__info">
                          <div class="added-item__name">{{ task.type_label }}</div>
                          <div class="added-item__additional">
                            <div v-if="task.actual_start_date"
                                 class="added-item__additional-time">
                              {{ formatDateTime(task.actual_start_date) }}
                            </div>
                            <div v-if="task.actual_start_date"
                                 class="added-item__additional-time">-
                            </div>
                            <div v-if="task.actual_end_date"
                                 class="added-item__additional-time">
                              {{ formatDateTime(task.actual_end_date) }}
                            </div>
                          </div>
                        </div>
                        <div class="task-remaining">
                          <div v-show="task.state.isActive && task.planned_end_date">
                            Осталось:
                            <span
                              class="task-remaining__time"
                              :class="{'task-remaining__time--danger': getTaskTimeUntilEnd(task.planned_end_date) < 0}">
                              {{ getTaskTimeUntilEnd(task.planned_end_date) }} ч.
                            </span>
                          </div>
                        </div>
                        <div class="added-item__right">
                          {{ task.state.label }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div :class="{'active': activeTab === 1}"
                     class="tabs__item">
                  <div class="warehouse-stocks">
                    <div
                      v-for="(stock, index) in warehouseStocks"
                      :key="index"
                      class="warehouse-stocks__item">
                      <div class="warehouse-stocks__item-consumable">{{stock.consumableName}}</div>
                      <div class="warehouse-stocks__item-count">
                        Доступно: {{stock.available}} {{stock.consumableMeasure}}
                        <span v-if="stock.reserv > 0">(В резерве: {{stock.reserv}})</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      <base-side-modal-skeleton v-if="loading"/>
    </div>
    <div
      v-if="Object.keys(resource).length"
      class="i-modal-btn-wp i-modal-btn-wp--back"
    >
      <div class="task-modal__mobile-actions">
        <button
          @click="$store.dispatch('baseSideModal/closeModal')"
          class="button button--mode-outline task-modal__mobile-back"
        >
          <icon-arrow-left/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import BaseSideModalSkeleton from "@/components/BaseSideModalSkeleton.vue";
import {mapState} from "vuex";
import {formatDateTime, nullFilter} from "@/utils/filters";
import moment from "moment";

export default {
  name: "ResourceModalContent",
  components: {
    BaseSideModalSkeleton,
    IconArrowLeft
  },
  data() {
    return {
      activeTab: 0
    }
  },
  computed: {
    ...mapState({
      resource: state => state.resourceModal.resource,
      warehouseStocks: state => state.resourceModal.stocks,
      tasks: state => state.resourceModal.resourceTasks,
      loading: state => state.resourceModal.loading
    }),
    groupedTasks() {
      const grouped = this.tasks.reduce((acc, task) => {
        const [date] = task.actual_start_date.split(" ");
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(task);
        return acc;
      }, {});

      return Object.keys(grouped)
        .sort()
        .reduce((sorted, date) => {
          sorted[date] = grouped[date];
          return sorted;
        }, {});
    }
  },
  methods: {
    formatDateTime,
    nullFilter,
    formatTaskDate(date) {
      return moment(date).format('DD MMMM')
    },
    getTaskTimeUntilEnd(plannedEndDate) {
      return moment(plannedEndDate).diff(this.$moment(), 'hours')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.resource-tasks {

  &__date {
    @include font-style(500, 16px, 24px, var(--text-primary));
    margin-bottom: 8px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 4px;

    & .added-item__info {
      flex: 1;
    }

    & .added-item__right {
      flex: .3;
      justify-content: flex-end;
    }
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.task-remaining {
  @include font-style(400, 14px, 16px, var(--text-secondary));

  &__time {
    color: var(--text-primary);

    &--danger {
      color: var(--border-validation);
    }
  }
}

.warehouse-stocks {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__item {
    padding: 16px;
    border-radius: 16px;
    border: 1px solid var(--border-main);

    &-consumable {
      margin-bottom: 4px;
      @include font-style(400, 14px, 16px, var(--text-primary));
    }

    &-count {
      @include font-style(400, 14px, 16px, var(--text-secondary));
    }
  }
}
</style>
