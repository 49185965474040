import Damage from "@/models/serviceDesk/Damage";

export default class Leak {
    constructor(id, attributes) {
        this.id = id;
        this.actualStartDate = attributes.actual_start_date;
        this.actualEndDate = attributes.actual_end_date;
        this.volume = parseFloat(attributes.volume);
        this.damagesList = attributes.damages.map(damage => new Damage({id: damage.id, ...damage.attributes}));
    }
}