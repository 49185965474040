<template>
  <div class="base-modal-info__item">
    <div class="base-modal-info__name">
      <icon-document-text/>
      <span>Наряд-допуск</span>
    </div>
    <div class="base-modal-info__value">
      <div class="task-permit">
        <div
          v-if="!permit.start_date"
          class="task-permit__get">
          <button
            @click="showUploadPermitPopup(true)"
            class="button button--mode-skinny button--mode-primary">
            Загрузить наряд допуск
          </button>
          <div @click="downloadPermitTemplate()">Скачать документ</div>
        </div>
        <div
          v-if="permit.start_date"
          class="task-permit__new"
        >
          <div>Выдан {{formatDateTime(permit.start_date)}}</div>
          <a
            class="button button--mode-skinny"
            :href="permit.start_file_url"
            target="_blank"
          >
            Наряд-допуск.pdf
          </a>
        </div>
        <div
          v-if="permit.start_date && !permit.finish_date && isTaskReview"
          class="task-permit__close">
          <button
            @click="showUploadPermitPopup(false)"
            class="button button--mode-skinny button--mode-primary"
          >
            Закрыть наряд допуск
          </button>
        </div>
        <div
          v-if="permit.finish_date"
          class="task-permit__closed">
          <div>Закрыт {{formatDateTime(permit.finish_date)}}</div>
          <a
            class="button button--mode-skinny"
            :href="permit.finish_file_url"
            target="_blank"
          >
            Наряд-допуск.pdf
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconDocumentText from "@/components/Icons/IconDocumentText.vue";
import {showAlert} from "@/utils/notification";
import UploadPermitModal from "@/components/ServiceDesk/Tasks/TaskComponents/Permit/UploadPermitModal.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import {formatDateTime} from "@/utils/filters";

export default {
  name: "TaskPermit",
  components: {IconDocumentText},
  props: {
    permit: {},
    taskId: {
      type: Number,
      required: true
    },
    isTaskReview: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      popupName: 'upload-permit-popup'
    }
  },
  methods: {
    formatDateTime,
    showUploadPermitPopup(isStart) {
      this.$modal.show(
        UploadPermitModal,
        {
          popupName: this.popupName,
          isStartForm: isStart,
          taskId: this.taskId
        },
        {
          name: this.popupName,
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    downloadPermitTemplate() {
      this.$store.dispatch('task/getTaskPermit', this.taskId)
        .catch(error => {
          showAlert('Ошибка', error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.task-permit {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__get {
    display: flex;
    align-items: center;
    gap: 24px;

    & div {
      color: var(--text-link);
      cursor: pointer;
    }
  }

  &__new {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__close {
    display: flex;
  }

  &__closed {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}
</style>
