export default class TaskState {
    static NEW = 1;
    static IN_WORK = 4;
    static CANCELED = 5;
    static CLOSED = 6;
    static SUSPENDED = 7;
    static ON_THE_WAY = 8;
    static REVIEW = 9;

    constructor(id) {
        this.id = id;
        this.label = this.getStateLabel();
        this.color = this.getStateColor();
    }

    getStateLabel() {
        switch (this.id) {
            case TaskState.NEW:
                return 'Новое'
            case TaskState.IN_WORK:
                return 'В работе'
            case TaskState.CANCELED:
                return 'Отменено'
            case TaskState.CLOSED:
                return 'Закрыто'
            case TaskState.SUSPENDED:
                return 'Приостановлено'
            case TaskState.ON_THE_WAY:
                return 'В пути'
            case TaskState.REVIEW:
                return 'На проверке'
            default:
                return 'Статус отсутствует'
        }
    }

    getStateColor() {
        switch (this.id) {
            case TaskState.NEW:
                return '#468FC5'
            case TaskState.IN_WORK:
                return '#FFBF1C'
            case TaskState.CANCELED:
                return '#E02800'
            case TaskState.CLOSED:
                return '#4f4f4f'
            case TaskState.SUSPENDED:
                return '#4f4f4f'
            case TaskState.ON_THE_WAY:
                return '#1AB9EB'
            case TaskState.REVIEW:
                return '#1AB9EB'
            default:
                return '#7c7c7c'
        }
    }

    get isNew() {
        return this.id === TaskState.NEW;
    }

    get isInWork() {
        return this.id === TaskState.IN_WORK;
    }

    get isActive() {
        return this.id !== TaskState.CLOSED && this.id !== TaskState.CANCELED;
    }

    get isCanceled() {
        return this.id === TaskState.CANCELED;
    }

    get isReview() {
        return this.id === TaskState.REVIEW;
    }
}
