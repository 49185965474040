import { render, staticRenderFns } from "./BaseSuggestions.vue?vue&type=template&id=2fa9b2bf&scoped=true&"
import script from "./BaseSuggestions.vue?vue&type=script&lang=js&"
export * from "./BaseSuggestions.vue?vue&type=script&lang=js&"
import style0 from "./BaseSuggestions.vue?vue&type=style&index=0&id=2fa9b2bf&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fa9b2bf",
  null
  
)

export default component.exports