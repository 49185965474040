<template>
  <div class="page-wrapper">
    <base-panel
      title="Ресурсы"
      :is-add-button-visible="false"
    />

    <resources-list
      :resources-list="resourcesList"
      :is-actions-visible="false"
      @show-resource="showResourceModal"
    />

    <base-content-loaders v-if="loading && !resourcesList.length" />

    <base-no-data v-if="!loading && !resourcesList.length" />

    <base-paginate
      v-if="meta && meta.last_page > 1"
      :meta="meta"
      action="resources/getResourcesList"
      commit="resources/SET_PAGE"
    />
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import ResourcesList from "@/components/ServiceDesk/admin/Resources/ResourcesList.vue";
import {mapState} from "vuex";
import BasePaginate from "@/components/BasePaginate.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";

export default {
  name: "ResourcesListPage",
  components: {BaseNoData, BaseContentLoaders, BasePaginate, ResourcesList, BasePanel},
  computed: {
    ...mapState({
      resourcesList: state => state.resources.resourcesList,
      loading: state => state.resources.loading,
      meta: state => state.resources.meta
    })
  },
  mounted() {
    this.$store.dispatch('resources/getResourcesList');
  },
  methods: {
    showResourceModal(resourceId) {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'resource',
        entityId: resourceId
      });
    }
  }
}
</script>

<style scoped>

</style>
