import {TechnicalInspectionObjectLastTask} from "@/models/TechnicalInspection/TechnicalInspectionObjectLastTask";
import NetworkObject from "@/models/telemetry/NetworkObject";

export class TechnicalInspectionObject extends NetworkObject {
    constructor(params) {
        super({id: params.id, attributes: params})

        this.lastTask = new TechnicalInspectionObjectLastTask({
            id: params.last_task.id,
            ...params.last_task.attributes
        });
    }
}
