<template>
  <base-collapse :default-collapse-state="true">
    <template #toggle="{toggle, isCollapsed}">
      <div class="base-modal-info__item">
        <div
          v-show="isFormNameVisible"
          class="base-modal-info__name"
        />
        <div
          @click="toggle()"
          class="base-modal-info__value manual-address__toggle">
          <span>Ввести адрес вручную</span>
          <div :class="{'rotateZ-180deg': !isCollapsed}">
            <icon-arrow-down />
          </div>
        </div>
      </div>
    </template>

    <template #body>
      <div class="manual-address__form">
        <div class="manual-address__form-item-top">
          <input
            :value="address_region"
            @input="updateField($event, 'address_region')"
            type="text"
            placeholder="Регион"
            class="base-input base-input--bordered"
          >

          <input
            :value="address_city"
            @input="updateField($event, 'address_city')"
            type="text"
            placeholder="Город"
            class="base-input base-input--bordered"
          >

          <input
            :value="address_settlement"
            @input="updateField($event, 'address_settlement')"
            type="text"
            placeholder="Район"
            class="base-input base-input--bordered"
          >
        </div>
        <div class="manual-address__form-item-bottom">
          <input
            :value="address_street"
            @input="updateField($event, 'address_street')"
            type="text"
            placeholder="Улица"
            class="base-input base-input--bordered"
          >

          <input
            :value="address_house"
            @input="updateField($event, 'address_house')"
            type="text"
            placeholder="Дом"
            class="base-input base-input--bordered"
          >

          <input
            :value="address_block"
            @input="updateField($event, 'address_block')"
            type="text"
            placeholder="Блок"
            class="base-input base-input--bordered"
          >

          <input
            :value="address_flat"
            @input="updateField($event, 'address_flat')"
            type="text"
            placeholder="Кв"
            class="base-input base-input--bordered"
          >
        </div>
      </div>
    </template>
  </base-collapse>
</template>

<script>
import BaseCollapse from "@/components/BaseCollapse.vue";
import IconArrowDown from "@/components/Icons/IconArrowDown.vue";

export default {
  name: "ManualAddressForm",
  components: {IconArrowDown, BaseCollapse},
  props: {
    isFormNameVisible: {
      type: Boolean,
      default: true
    },
    address_region: {
      required: true
    },
    address_city: {
      required: true
    },
    address_settlement: {
      required: true
    },
    address_street: {
      required: true
    },
    address_house: {
      required: true
    },
    address_block: {
      required: true
    },
    address_flat: {
      required: true
    }
  },
  methods: {
    updateField(event, fieldName) {
      this.$emit(`update:${fieldName}`, event.target.value);
    }
  }
}
</script>

<style scoped>

</style>
