import DamagesService from "@/services/DamagesService";

export const namespaced = true

export const actions = {
    createDamage(context, {issueId, leakId, payload}) {
        return new Promise((resolve, reject) => {
            DamagesService.createDamage(issueId, leakId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateDamage(context, {issueId, leakId, damageId, payload}) {
        return new Promise((resolve, reject) => {
            DamagesService.updateDamage(issueId, leakId, damageId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    deleteDamage(context, {issueId, leakId, damageId}) {
        return new Promise((resolve, reject) => {
            DamagesService.deleteDamage(issueId, leakId, damageId)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}