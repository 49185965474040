<template>
  <div class="base-table">
    <div class="base-table__container">
      <div class="base-table__header base-table__column--technical-inspection-object">
        <div class="base-table__header-cell">ID</div>
        <div class="base-table__header-cell">Наименование</div>
        <div class="base-table__header-cell">Тип</div>
        <div class="base-table__header-cell base-table__header-cell--center">Текущие нарушения</div>
        <div class="base-table__header-cell">Последний обход</div>
      </div>

      <div v-for="object in objectsList"
           :key="object.id"
           @click="$emit('show-object', object.id)"
           class="base-table__row base-table__column--technical-inspection-object">
        <div class="base-table__column base-table__column--number">
          <div class="base-table__num">{{ object.id }}</div>
        </div>
        <div class="base-table__column base-table__column--emergency-elements">
          <div class="base-table__text">{{ object.name }}</div>
          <div class="base-table__text--secondary">{{object.address}}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ object.type.name }}</div>
        </div>
        <div class="base-table__column base-table__column--content-center">
          <div class="base-table__text">{{ object.activeViolations }}</div>
        </div>
        <div class="base-table__column base-table__column--emergency-elements">
          <div class="base-table__text">{{ formatDateTime(object.lastInspectionDate) }}</div>
          <div class="base-table__text--secondary">{{object.lastInspectionResource}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {formatDateTime} from "@/utils/filters";

export default {
  name: "TechnicalInspectionObjectsList",
  props: {
    objectsList: {
      type: Array,
      required: true
    }
  },
  methods: {formatDateTime}
}
</script>

<style scoped>
.base-table__column--technical-inspection-object {
  grid-template-columns: 1fr 5fr 2fr 2fr 2fr;
}
</style>
