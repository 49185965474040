<template>
  <div
    class="i-modal is-default-hidden"
    :class="{'is-active': isModalVisible, 'is-horizontal': isHorizontal}"
  >
    <div @click="closeModal()"
         class="i-modal__float-close">
      <icon-close-modal/>
    </div>

    <div @click="goBack()"
         class="i-modal__float-back"
         :class="{'visible': doseTheHistoryHavePreviousItem}">
      <icon-arrow-double/>
    </div>

    <component
      :is="contentComponent"
      v-bind="modalContentProps"
    />
  </div>
</template>

<script>
import IconCloseModal from "@/components/Icons/IconCloseModal.vue";
import {mapGetters, mapState} from "vuex";
import AppealModalContent from "@/components/CallCenter/AppealModalContent.vue";
import CreateAppealModalContent from "@/components/CallCenter/CreateAppealModalContent.vue";
import IssueModalContent from "@/components/ServiceDesk/Issues/IssueModalContent.vue";
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import IconArrowDouble from "@/components/Icons/IconArrowDouble.vue";
import TaskModalContent from "@/components/ServiceDesk/Tasks/TaskModalContent.vue";
import CreateIssueModalContent from "@/components/ServiceDesk/Issues/CreateIssueModalContent.vue";
import WorkModalContent from "@/components/ServiceDesk/admin/Works/WorkModalContent.vue";
import DepartmentContentModal from "@/components/ServiceDesk/admin/Departments/DepartmentContentModal.vue";
import TransportModalContent from "@/components/Transport/TransportModalContent.vue";
import TransportMovementHistoryModalContent from "@/components/Transport/TransportMovementHistoryModalContent.vue";
import TransportFormModalContent from "@/components/Transport/admin/TransportFormModalContent.vue";
import LayerModalContent from "@/components/EngineeringNetworks/MapLayers/LayerModalContent.vue";
import EnergyConsumptionObjectModalContent
  from "@/components/EnergyConsumption/admin/EnergyConsumptionObjectModalContent.vue";
import NetworkObjectFormModalContent
  from "@/components/Telemetry/admin/NetworkObject/NetworkObjectFormModalContent.vue";
import NetworkObjectModalContent from "@/components/Telemetry/admin/NetworkObject/NetworkObjectModalContent.vue";
import EquipmentModalContent from "@/components/Telemetry/admin/NetworkObject/EquipmentModalContent.vue";
import DeviceModalContent from "@/components/Telemetry/admin/NetworkObject/DeviceModalContent.vue";
import RoleModalContent from "@/components/admin/RoleModalContent.vue";
import UserModalContent from "@/components/admin/User/UserModalContent.vue";
import UserFormModalContent from "@/components/admin/User/UserFormModalContent.vue";
import IssueUpdateModalContent from "@/components/ServiceDesk/Issues/IssueUpdateModalContent.vue";
import ShutdownNotificationFormModalContent
  from "@/components/ServiceDesk/SutdownNotifications/ShutdownNotificationFormModalContent.vue";
import ShutdownNotificationModalContent
  from "@/components/ServiceDesk/SutdownNotifications/ShutdownNotificationModalContent.vue";
import ScadaNetworkEquipmentModalContent from "@/components/Telemetry/Scada/ScadaNetworkEquipmentModalContent.vue";
import TaskCreateModalContent from "@/components/TechnicalInspection/Tasks/TaskCreateModalContent.vue";
import TechnicalInspectionTaskModalContent from '@/components/TechnicalInspection/Tasks/TaskModalContent.vue'
import TechnicalInspectionObjectModalContent
  from "@/components/TechnicalInspection/NetworkObjects/TechnicalInspectionObjectModalContent.vue";
import ResourceModalContent from "@/components/ServiceDesk/Resources/ResourceModalContent.vue";

export default {
  name: "BaseSideModal",
  components: {IconArrowDouble, IconArrowLeft, IconCloseModal},
  data() {
    return {
      modalContentTypesList: {
        'appeal': AppealModalContent,
        'createAppeal': CreateAppealModalContent,
        'issue': IssueModalContent,
        'task': TaskModalContent,
        'createIssue': CreateIssueModalContent,
        'department': DepartmentContentModal,
        'work': WorkModalContent,
        'transport': TransportModalContent,
        'transportMovementHistory': TransportMovementHistoryModalContent,
        'vehicleForm': TransportFormModalContent,
        'layer': LayerModalContent,
        'energyConsumptionObject': EnergyConsumptionObjectModalContent,
        'networkObjectForm': NetworkObjectFormModalContent,
        'networkObject': NetworkObjectModalContent,
        'networkObjectEquipment': EquipmentModalContent,
        'networkObjectDevice': DeviceModalContent,
        'role': RoleModalContent,
        'user': UserModalContent,
        'userForm': UserFormModalContent,
        'updateIssue': IssueUpdateModalContent,
        'shutdownNotificationForm': ShutdownNotificationFormModalContent,
        'shutdownNotification': ShutdownNotificationModalContent,
        'equipment': ScadaNetworkEquipmentModalContent,
        'createTechnicalInspectionTask': TaskCreateModalContent,
        'technicalInspectionTask': TechnicalInspectionTaskModalContent,
        'technicalInspectionObject': TechnicalInspectionObjectModalContent,
        'resource': ResourceModalContent
      }
    }
  },
  computed: {
    ...mapState({
      isModalVisible: state => state.baseSideModal.isModalVisible,
      isHorizontal: state => state.baseSideModal.isModalHorizontal,
      modalContentType: state => state.baseSideModal.modalContentType,
      modalContentProps: state => state.baseSideModal.componentPropsData
    }),
    ...mapGetters({
      doseTheHistoryHavePreviousItem: 'baseSideModal/doseTheHistoryHavePreviousItem'
    }),
    contentComponent() {
      return this.modalContentTypesList[this.modalContentType];
    }
  },
  methods: {
    closeModal() {
      this.$store.dispatch('baseSideModal/closeModal');
    },
    goBack() {
      this.$store.dispatch('baseSideModal/goBack');
    }
  }
}
</script>

<style scoped>

</style>
