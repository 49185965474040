import TasksService from "@/services/TasksService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    task: {},
    loading: false,
    actionLoading: false,
    error: null
}

export const mutations = {
    SET_TASK(state, task) {
        state.task = task;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ACTION_LOADING(state, loading) {
        state.actionLoading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getTask({commit}, task_id) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            TasksService.getTask(task_id)
                .then(task => {
                    commit("SET_TASK", task);
                    resolve(task);
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
                .finally(() => commit("SET_LOADING", false));
        })
    },
    addFile({commit}, payload) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            TasksService.addFile(payload)
                .then(() => resolve())
                .catch(error => {
                    const error_text = getError(error);
                    reject(error_text);
                    commit("SET_ERROR", error_text);
                })
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    deleteFile({commit}, {taskId, fileId}) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            TasksService.deleteFile(taskId, fileId)
                .then(() => resolve())
                .catch(error => reject(error))
                .finally(() => commit("SET_ACTION_LOADING", true));
        })
    },
    assignBrigade({commit}, payload) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            TasksService.assignBrigade(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    acceptTask({commit}, task_id) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            TasksService.acceptTask(task_id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    cancelTask({commit}, task_id) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            TasksService.cancelTask(task_id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    onTheWayTask({commit}, task_id) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            TasksService.onTheWayTask(task_id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    startTask({commit}, task_id) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            TasksService.startTask(task_id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    suspendTask({commit}, task_id) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            TasksService.suspendTask(task_id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    resumeTask({commit}, task_id) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            TasksService.resumeTask(task_id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    completeTask({commit}, task_id) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            TasksService.completeTask(task_id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    reviewTask({commit}, task_id) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            TasksService.reviewTask(task_id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    addWork({commit}, {task_id, payload}) {
        return new Promise((resolve, reject) => {
            TasksService.addWork({task_id, payload})
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject(getError(error));
                })
        })
    },
    deleteWork(context, {taskId, workId}) {
        return new Promise((resolve, reject) => {
            TasksService.removeWork(taskId, workId)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    addComment({commit}, {task_id, payload}) {
        return new Promise((resolve, reject) => {
            TasksService.addComment({task_id, payload})
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject(getError(error));
                })
        })
    },
    appointDepartment(context, {task_id, payload}) {
        return new Promise((resolve, reject) => {
            TasksService.appointDepartment(task_id, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    changePlanning(context, {task_id, payload}) {
        return new Promise((resolve, reject) => {
            TasksService.changePlanning(task_id, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    getTaskPermit(context, taskId) {
        return new Promise((resolve, reject) => {
            TasksService.fetchTaskPermit(taskId)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    startTaskPermit(context, payload) {
        return new Promise((resolve, reject) => {
            TasksService.startTaskPermit(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    finishTaskPermit(context, payload) {
        return new Promise((resolve, reject) => {
            TasksService.finishTaskPermit(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    }
}

export const getters = {
    getTask: state => {
        return state.task;
    },
    getTaskId: state => {
        return state.task.task.id;
    }
}
