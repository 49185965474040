<template>
  <div class="i-modal__content-wrapper">
    <div class="i-modal__content">
      <div class="i-modal__inner">
        <div class="base-modal__left-side">
          <div class="base-modal-header">
            <span
              v-if="!isUpdateForm"
              class="base-modal-title">Новый пользователь</span>
            <span
              v-else
              class="base-modal-title">Редактирование пользователя</span>
          </div>
          <div class="base-modal-info">
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Имя</span>
              </div>
              <div class="base-modal-info__value">
                <input
                  type="text"
                  v-model="payload.name"
                  class="base-input base-input--bordered"
                  placeholder="Введите имя и фамилию"
                >
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Email</span>
              </div>
              <div class="base-modal-info__value">
                <input
                  type="text"
                  v-model="payload.email"
                  class="base-input base-input--bordered"
                  placeholder="Введите email"
                >
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Телефон</span>
              </div>
              <div class="base-modal-info__value">
                <input
                  type="text"
                  v-model="payload.phone"
                  class="base-input base-input--bordered"
                  placeholder="Введите телефон"
                >
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Должность</span>
              </div>
              <div class="base-modal-info__value">
                <input
                  type="text"
                  v-model="payload.job_title"
                  class="base-input base-input--bordered"
                  placeholder="Введите должность"
                >
              </div>
            </div>
            <div class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Структ. подразделение</span>
              </div>
              <div class="base-modal-info__value">
                <v-select
                  class="vue-select-input"
                  v-model="payload.structural_unit_id"
                  :options="structuralUnits"
                  :reduce="unit => unit.id"
                  :get-option-label="(option) => option.name"
                  placeholder="Выберите из списка"
                />
              </div>
            </div>
            <div
              v-if="!isUpdateForm"
              class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Пароль</span>
              </div>
              <div class="base-modal-info__value">
                <input
                  type="password"
                  v-model="payload.password"
                  class="base-input base-input--bordered"
                  placeholder="Введите пароль"
                  autocomplete="new-password"
                >
              </div>
            </div>
            <div
              v-if="!isUpdateForm"
              class="base-modal-info__item">
              <div class="base-modal-info__name">
                <span>Пароль еще раз</span>
              </div>
              <div class="base-modal-info__value">
                <input
                  type="password"
                  v-model="payload.password_confirmation"
                  class="base-input base-input--bordered"
                  placeholder="Введите пароль"
                >
              </div>
            </div>
            <div class="base-modal-btn-wp create-user-buttons">
              <div @click="submitForm()"
                   class="button button--mode-primary">
                <span v-if="!isUpdateForm">Создать</span>
                <span v-else>Сохранить</span>
              </div>
              <div @click="cancelUserCreate()"
                   class="button button--mode-outline">
                <span>Отменить</span>
              </div>
            </div>
          </div>
        </div>
        <div class="i-modal-btn-wp i-modal-btn-wp--back">
          <div class="task-modal__mobile-actions">
            <button @click="$store.dispatch('baseSideModal/goBack')"
                    class="button button--mode-outline task-modal__mobile-back">
              <icon-arrow-left/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "UserFormModalContent",
  components: {
    IconArrowLeft
  },
  data() {
    return {
      payload: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        phone: null,
        job_title: null,
        structural_unit_id: null
      }
    }
  },
  computed: {
    ...mapState({
      structuralUnits: state => state.structuralUnits.structuralUnits,
      propsData: state => state.baseSideModal.componentPropsData
    }),
    isUpdateForm() {
      return Object.keys(this.propsData).length
    }
  },
  mounted() {
    if (this.isUpdateForm) {
      const {name, email, phone, job_title, structuralUnit} = this.propsData;
      this.payload.name = name;
      this.payload.email = email;
      this.payload.phone = phone;
      this.payload.job_title = job_title;
      this.payload.structural_unit_id = structuralUnit.id;
    }
  },
  methods: {
    submitForm() {
      if (!this.isUpdateForm) this.createUser()
      else this.updateUser()
    },
    createUser() {
      this.$store.dispatch('user/createUser', this.payload)
        .then(() => {
          this.$store.dispatch('baseSideModal/goBack');
          this.$store.dispatch('user/getUsers');
          showToast('Пользователь создан', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    updateUser() {
      this.$store.dispatch('user/updateUser', {
        userId: this.propsData.id,
        payload: this.payload
      })
        .then(() => {
          this.$store.dispatch('baseSideModal/goBack');
          this.$store.dispatch('user/getUsers');
          showToast('Пользователь обновлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error));
    },
    cancelUserCreate() {
      this.$store.dispatch('baseSideModal/goBack');
    }
  }
}
</script>

<style lang="scss" scoped>
.create-user-buttons {
  display: flex;
  gap: 12px;

  & .button {
    flex: 1;
  }
}
</style>
