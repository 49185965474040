<template>
  <div class="base-table">
    <div class="base-table__container">
      <base-table-header
        :table-headers="tableHeaders"
        header-class-name="base-table__column--resource"
        @update-sort-by="value => sortBy = value"
        @update-sort-order="value => sortOrder = value"
      />

      <div v-for="resource in resourcesList"
           :key="resource.id"
           @click="$emit('show-resource', resource.id)"
           class="base-table__row base-table__column--resource">
        <div class="base-table__column">
          <div class="base-table__text">{{ resource.id }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{ resource.name }}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{resource.master.name}}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{resource.transport.name}}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{resource.department.name}}</div>
        </div>
        <div class="base-table__column">
          <div class="base-table__text">{{resource.structuralUnit.name}}</div>
        </div>
        <div class="base-table__column base-table__column--content-right">
          <div
            v-if="isActionsVisible"
            class="base-table__short-info">
            <div @click="$emit('update-resource', resource)">
              <icon-pencil class="base-table__short-info--p-2" />
            </div>
            <div @click="$emit('delete-resource', resource.id)">
              <icon-trash />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTrash from "@/components/Icons/IconTrash.vue";
import IconPencil from "@/components/Icons/IconPencil.vue";
import BaseTableHeader from "@/components/BaseTableHeader.vue";

export default {
  name: "ResourcesList",
  components: {BaseTableHeader, IconPencil, IconTrash},
  props: {
    resourcesList: {
      type: Array,
      required: true
    },
    isActionsVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tableHeaders: [
        {id: 1, name: 'ID', sortName: null, sortDir: null},
        {id: 2, name: 'Наименование', sortName: 'resource_name', sortDir: null},
        {id: 3, name: 'Мастер', sortName: 'master_name', sortDir: null},
        {id: 4, name: 'Транспорт', sortName: null, sortDir: null},
        {id: 5, name: 'Подразделение', sortName: 'department_name', sortDir: null},
        {id: 6, name: 'Структурное подразделение', sortName: 'unit_name', sortDir: null},
        {id: 7, name: '', sortName: null, sortDir: null}
      ]
    }
  },
  computed: {
    sortBy: {
      get() {
        return this.$store.state.resources.sortBy
      },
      set(value) {
        this.$store.commit('resources/SET_SORT_BY', value)
      }
    },
    sortOrder: {
      get() {
        return this.$store.state.resources.sortOrder
      },
      set(value) {
        this.$store.commit('resources/SET_SORT_ORDER', value)
      }
    }
  },
  watch: {
    sortBy: function (value, oldValue) {
      if (value !== oldValue) this.$store.dispatch('resources/getResourcesList')
    },
    sortOrder: function () {
      this.$store.dispatch('resources/getResourcesList')
    }
  }
}
</script>

<style>
.base-table__column--resource {
  grid-template-columns: 60px repeat(4, 1fr) 250px 100px;
}
</style>
