<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      <template v-if="isCreateForm">Добавить тип задания</template>
      <template v-else>Редактирование типа задания</template>
      <span @click="$modal.hide('task-type-form')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group">
      <div class="form-item">
        <div class="form-item__name">Наименование</div>
        <div class="form-item__value">
          <input
            v-model="payload.name"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите наименование"
            required
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Тег</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.tag"
            :options="tag.tags"
            :reduce="tag => tag.id"
            :get-option-label="(option) => option.label"
            class="vue-select-input"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Группы проблем</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.category_groups"
            :options="categoryGroupsList"
            :reduce="group => group.id"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"
            multiple
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Компоненты</div>
        <div class="form-item__value form-item__value--column form-item__value--items-left">
          <v-select
            v-model="selectedFeature"
            :options="filteredFeaturesList"
            :reduce="feature => feature"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"
            @input="addFeature"
          />

          <div class="task-type__features">
            <div
              v-for="feature in selectedFeaturesList"
              :key="feature.slug"
              class="task-type__feature">
              <div class="task-type__feature-name">{{feature.name}}</div>
              <div class="task-type__feature-actions">
                <span v-if="feature.required">Обязательный</span>
                <span v-else>Не обязательный</span>
                <div class="task-type__feature-checkbox">
                  <label class="base-checkbox base-checkbox--squared">
                    <input
                      v-model="feature.required"
                      type="checkbox"
                    >
                    <span/>
                  </label>
                </div>
                <div
                  @click="deleteFeature(feature.slug)"
                  class="task-type__feature-delete">
                  <icon-close/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Максимальное время выполнения в часах</div>
        <div class="form-item__value">
          <input
            v-model.number="payload.lead_time"
            type="text"
            class="base-input base-input--bordered"
            placeholder="Введите количество часов"
            required
          >
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Этап</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.stage"
            :options="stagesList"
            :reduce="stage => stage.id"
            :get-option-label="(option) => option.name"
            class="vue-select-input"
            placeholder="Выберите из списка"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item__name">Мероприятия</div>
        <div class="form-item__value">
          <textarea
            v-model="payload.safety_measures"
            class="base-textarea"
            placeholder="Напишите список"
          />
        </div>
      </div>
      <label class="base-checkbox base-checkbox--squared">
        <input
          v-model="payload.check_safety"
          type="checkbox"
        >
        <span>Необходимость проверки ТБ</span>
      </label>
      <div
        v-if="!isCreateForm"
        class="form-item">
        <label class="base-switcher">
          <input
            v-model="payload.active"
            class="base-switcher__checkbox"
            type="checkbox">
          <div class="base-switcher__switch"/>
          <span class="base-switcher__label">
            Активна
          </span>
        </label>
      </div>
    </div>
    <div class="base-popup__footer">
      <div @click="checkFormType()"
           class="button">
        Сохранить
      </div>
    </div>
  </div>
</template>

<script>
import Tag from '@/models/serviceDesk/Tag'
import IconClose from "@/components/Icons/IconClose.vue";

export default {
  name: "TaskTypesForm",
  components: {IconClose},
  props: {
    taskType: {
      type: Object,
      required: true
    },
    categoryGroupsList: {
      type: Array,
      required: true
    },
    featuresList: {
      type: Array,
      required: true
    },
    createTaskType: {
      type: Function,
      default: () => {}
    },
    updateTaskType: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      payload: {
        name: null,
        tag: null,
        components: null,
        category_groups: [],
        lead_time: null,
        stage: null,
        safety_measures: null,
        check_safety: false
      },
      selectedFeaturesList: [],
      selectedFeature: null,
      stagesList: [
        {id: 1, name: 'Подготовка'},
        {id: 2, name: 'Устранение проблемы'}
      ]
    }
  },
  computed: {
    tag: () => Tag,
    isCreateForm() {
      return !Object.keys(this.taskType).length
    },
    filteredFeaturesList() {
      return this.featuresList.filter(item => !this.selectedFeaturesList.some(feature => feature.slug === item.slug));
    }
  },
  mounted() {
    if (!this.isCreateForm) {
      const {name, tag, features, categoryGroups, leadTime, stage, active, checkSafety, safetyMeasures} = this.taskType;
      this.payload.name = name;
      this.payload.tag = tag;
      this.payload.category_groups = categoryGroups.map(item => item.id);
      this.payload.lead_time = leadTime;
      this.payload.stage = stage.id;
      this.payload.active = active;
      this.payload.check_safety = checkSafety;
      this.payload.safety_measures = safetyMeasures;

      this.selectedFeaturesList = [...features];
    }
  },
  methods: {
    checkFormType() {
      this.payload.components = this.selectedFeaturesList.map(item => {
        return {
          name: item.slug,
          required: item.required ?? false
        }
      });

      if (!this.isCreateForm) this.updateTaskType(this.taskType.id, this.payload)
      else this.createTaskType(this.payload);
    },
    addFeature(value) {
      this.selectedFeaturesList.push(value);
      this.selectedFeature = null;
    },
    deleteFeature(slug) {
      const index = this.selectedFeaturesList.findIndex(item => item.slug === slug);
      this.selectedFeaturesList.splice(index, 1);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/helpers/mixins";

.task-type {
  &__features {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__feature {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 6px;
    padding-top: 6px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--border-main);
    }

    &-name {
      @include font-style(400, 14px, 16px, var(--text-primary));
    }

    &-actions {
      display: flex;
      align-items: center;
      gap: 12px;

      & span {
        font-size: 12px;
        gap: 8px;
        color: var(--text-secondary);
      }
    }

    &-checkbox {
      height: 22px;
    }

    &-delete {
      cursor: pointer;
    }
  }
}

.base-checkbox {
  & span {
    gap: 16px;
  }
}
</style>
