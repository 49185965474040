import ResourcesService from "@/services/ResourcesService";
import TasksService from "@/services/TasksService";
import TaskState from "@/models/serviceDesk/TaskState";

export const namespaced = true;

export const state = {
    resource: {},
    resourceTasks: [],
    stocks: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_RESOURCE(state, resource) {
        state.resource = resource;
    },
    SET_RESOURCE_TASKS(state, tasksList) {
        state.resourceTasks = tasksList;
    },
    SET_STOCKS(state, stocks) {
        state.stocks = stocks;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    async getResourceData({commit, dispatch}, resourceId) {
        commit("SET_LOADING", true);

        await Promise.all([
            dispatch('getResource', resourceId),
            dispatch('getResourceTasks', resourceId),
            dispatch('getResourceWarehouseStocks', resourceId)
        ])
            .finally(() => {
                commit("SET_LOADING", false);
            });
    },
    getResource({commit}, resourceId) {
        ResourcesService.fetchResource(resourceId)
            .then(resource => commit('SET_RESOURCE', resource))
            .catch(error => commit('SET_ERROR', error))
    },
    getResourceWarehouseStocks({commit}, resourceId) {
        ResourcesService.fetchResourceWarehouseStocks(resourceId)
            .then(stocks => commit("SET_STOCKS", stocks))
            .catch(error => commit('SET_ERROR', error))
    },
    getResourceTasks({commit}, resourceId) {
        TasksService.fetchAllTasks({
            executor: resourceId,
            issue_states: [TaskState.NEW, TaskState.IN_WORK].join(',')
        })
            .then(tasks => commit("SET_RESOURCE_TASKS", tasks))
            .catch(error => commit('SET_ERROR', error))
    }
}
