import * as API from './API';
import {getError} from "@/utils/helpers";
import {Warehouse} from "@/models/serviceDesk/Warehouse";

export default {
    fetchWarehousesList() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/external-warehouses`)
                .then(response => {
                    resolve(response.data.data.map(item => new Warehouse(item)));
                })
                .catch(error => reject(getError(error)))
        })
    }
}
