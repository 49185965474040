import * as API from './API';
import {getError} from "@/utils/helpers";

export default {
    createDamage(issueId, leakId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/service-desk/issues/${issueId}/leaks/${leakId}/damages`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateDamage(issueId, leakId, damageId, payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/service-desk/issues/${issueId}/leaks/${leakId}/damages/${damageId}`, payload)
              .then(() => resolve())
              .catch(error => reject(getError(error)));
        })
    },
    deleteDamage(issueId, leakId, damageId) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/service-desk/issues/${issueId}/leaks/${leakId}/damages/${damageId}`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    }
}