import * as API from "@/services/API";
import {getError} from "@/utils/helpers";
import TaskType from "@/models/serviceDesk/TaskType";
import TaskTypesFeature from "@/models/serviceDesk/TaskTypesFeature";

export default {
    getAllTaskTypes() {
        return new Promise((resolve, reject) => {
            API.apiClient.get('service-desk/task-types/all')
                .then(response => {
                    resolve(response.data.data.map(task_type => new TaskType(task_type.id, task_type.attributes)));
                })
                .catch(error => reject(getError(error)))
        })
    },
    getTaskTypes(page) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/task-types?page=${page}`)
                .then(response => {
                    resolve({
                        task_types: response.data.data.map(task_type => {
                            return new TaskType(task_type.id, task_type.attributes)
                        }),
                        meta: response.data.meta,
                        links: response.data.links
                    });
                })
                .catch(error => reject(getError(error)));
        })
    },
    createTaskType(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post('service-desk/task-types', payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    updateTaskType(taskTypeId, payload) {
        return API.apiClient.patch(`service-desk/task-types/${taskTypeId}`, payload);
    },
    deleteTaskType(taskTypeId) {
        return API.apiClient.delete(`service-desk/task-types/${taskTypeId}`);
    },
    fetchTaskTypesFeatures() {
        return new Promise((resolve, reject) => {
            API.apiClient.get('/service-desk/task-types/components')
                .then(response => resolve(response.data.data.map(item => new TaskTypesFeature(item))))
                .catch(error => reject(getError(error)))
        })
    }
}