<template>
  <div
    class="map-marker">
    <map-icon-bg/>
    <div class="map-marker__inner">
      <div
        class="map-marker__img"
        :class="{'map-marker__img--bg-dark-grey': isActive}"
      >
        <component :is="iconComponentMap[typeGroup]"/>
      </div>
    </div>
  </div>
</template>

<script>
import MapIconBg from "@/components/Icons/MapIconBg.vue";
import NetworkObject from "@/models/telemetry/NetworkObject";

export default {
  name: "PersonalListNetworkObjectMapMarker",
  components: {MapIconBg},
  props: {
    typeGroup: {
      type: Number,
      default: 0
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconComponentMap: () => NetworkObject.getIconComponentsMap()
  }
}
</script>

<style scoped>

</style>
